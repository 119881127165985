import moment, { Moment } from 'moment'

export enum DateFilterType {
    NONE = 'none',
    BEFORE = 'before',
    AFTER = 'after',
    BETWEEN = 'between',
}

export const DISCRIMINATOR = 'DateFilter'

export class DateFilter {
    public readonly DISCRIMINATOR = DISCRIMINATOR

    public before?: Moment
    public after?: Moment
    // tslint:disable-next-line:variable-name
    private _type: DateFilterType = DateFilterType.NONE

    constructor(type?: DateFilterType, dates?: {
        before?: Moment,
        after?: Moment,
    }) {
        this.before = dates?.before
        this.after = dates?.after
        if (type) {
            this.type = type
        }
    }

    get type() {
        return this._type
    }

    set type(type: DateFilterType) {
        this._type = type
        switch(type) {
            case DateFilterType.NONE:
                delete this.after
                delete this.before
                break
            case DateFilterType.BEFORE:
                delete this.after
                break
            case DateFilterType.AFTER:
                delete this.before
                break
        }
    }

    get isValid() {
        switch(this._type) {
            case DateFilterType.BETWEEN:
                return moment.isMoment(this.before) && moment.isMoment(this.after)
                break
            case DateFilterType.BEFORE:
                return moment.isMoment(this.before)
                break
            case DateFilterType.AFTER:
                return moment.isMoment(this.after)
            default:
                return true
        }
    }
}
