import { EntityRepositoryInterface } from '@repositories/interfaces/EntityRepository'
import { Ordering } from '@repositories/interfaces/Ordering'
import { ResourceFilter } from '@models/utility/ResourceFilter'
import { Connection, ResponseFormat } from '@services/Connection/Interfaces'
import { BelongsTo } from '@models/domain/interfaces/relations/BelongsTo'
import { BelongsToMany } from '@models/domain/interfaces/relations/BelongsToMany'
import moment, { Moment } from 'moment'
import { DateFilter } from '@models/utility/DateFilter'
import kebabCase from '@helpers/kebabCase'
import { Property } from '@models/utility/Property'
import { Attribute } from '@models/domain/interfaces/Attribute'
import { RelationInterface } from '@models/domain/interfaces/relations/Interface'
import { DomainModel } from '@models/domain/interfaces/DomainModel'
import { toIso8601 } from '@helpers/toIso8601'

interface EntityRepositoryGraphQLNode {
    [key: string]: string | number | ({
        totalCount?: number,
        edges?: Array<{
            node: EntityRepositoryGraphQLNode,
        }>,
    })
}

export abstract class BaseEntityRepository<T extends DomainModel<T>> implements EntityRepositoryInterface<T> {

    get resourceIdentifierPlural() {
        return `${this.resourceIdentifier}s`
    }

    get restApiEndpoint() {
        return `${this.routePrefix ? '/' + this.routePrefix : ''}/${kebabCase(this.resourceIdentifierPlural)}`
    }

    // tslint:disable-next-line:no-any
    public abstract modelConstructor: (data?: any) => T
    public abstract resourceIdentifier: string
    public routePrefix?: string = undefined

    protected connection: Connection

    constructor(connection: Connection) {
        this.connection = connection
    }

    public async get(
        identifier: T[T['idAttribute']],
        properties?: Array<Property<T>>,
    ) {
        const response = await this.connection.post({
            path: `/graphql`,
            data: {
                query: `{
                    ${this.resourceIdentifier}(
                        ${this.modelConstructor().idAttribute}: "${this.getFullObjectIdentifier(identifier)}"
                    ) {
                        ${ this.createGraphQLNodeQuery(properties) }
                    }
                }`,
            },
            headers: {
                'Accept': 'application/ld+json',
                'Content-Type': 'application/json',
            },
        }) as {
            data: {
                data: {
                    [identifier: string]: EntityRepositoryGraphQLNode,
                },
            },
        }
        return this.parseGraphQLNode(response.data.data[this.resourceIdentifier]) as T & { id: string }
    }

    public async all(
        ordering?: Ordering<T>,
        filter?: ResourceFilter<T>,
        properties?: Array<Property<T>>,
    ): Promise<Array<T & { id: string }>> {
        let query = []
        if (ordering) {
            query.push(this.createGraphQLOrderingQuery(ordering))
        }
        if (filter) {
            query = query.concat(this.createGraphQLFilterQueries(filter))
        }
        let queryStr = ''
        if (query.length > 0) {
            queryStr = `(${query.join(', ')})`
        }
        const response = await this.connection.post({
            data: {
                query: `{
                    ${this.resourceIdentifierPlural}${queryStr} {
                        edges {
                            node {
                                ${ this.createGraphQLNodeQuery(properties) }
                            }
                        }
                    }
                }`,
            },
            path: '/graphql',
            headers: {
                'Accept': 'application/ld+json',
                'Content-Type': 'application/json',
            },
        }) as {
            data: {
                data: {
                    [identifier: string]: {
                        edges: Array<{
                            node: EntityRepositoryGraphQLNode,
                        }>,
                    },
                },
            },
        }
        return response.data.data[this.resourceIdentifierPlural].edges
            .map(edge => this.parseGraphQLNode(edge.node)) as Array<T & { id: string }>
    }

    public async paginate(
        itemsPerPage: number,
        cursor?: string,
        ordering?: Ordering<T>,
        filter?: ResourceFilter<T>,
        properties?: Array<Property<T>>,
    ) {
        let query = [`first: ${itemsPerPage}`]
        if (cursor) {
            query.push(`after: "${cursor}"`)
        }
        if (ordering) {
            query.push(this.createGraphQLOrderingQuery(ordering))
        }
        if (filter) {
            query = query.concat(this.createGraphQLFilterQueries(filter))
        }
        const graphqlQuery = `{
                    ${this.resourceIdentifierPlural}(${query.join(', ')}) {
                        totalCount
                        pageInfo {
                            endCursor
                            hasNextPage
                        }
                        edges {
                            node {
                                ${ this.createGraphQLNodeQuery(properties) }
                            }
                        }
                    }
                }`
        const response = await this.connection.post({
            data: {
                query: graphqlQuery,
            },
            path: '/graphql',
            headers: {
                'Accept': 'application/ld+json',
                'Content-Type': 'application/json',
            },
        }) as {
            data: {
                data: {
                    [identifier: string]: {
                        totalCount: number,
                        pageInfo: {
                            endCursor: string,
                            hasNextPage: boolean,
                        }
                        edges: Array<{
                            node: EntityRepositoryGraphQLNode,
                        }>,
                    },
                },
            },
        }
        const items = response.data.data[this.resourceIdentifierPlural].edges
            .map(edge => this.parseGraphQLNode(edge.node)) as Array<T & { id: string }>
        return {
            items,
            totalItems: response.data.data[this.resourceIdentifierPlural].totalCount,
            endCursor: response.data.data[this.resourceIdentifierPlural].pageInfo.endCursor,
            hasNextPage: response.data.data[this.resourceIdentifierPlural].pageInfo.hasNextPage,
        }
    }

    public async create(model: T): Promise<T & { id: string }> {
        await model.onCreate(this.connection)
        const response = await this.connection.post({
            path: `${this.restApiEndpoint}`,
            data: model.getRequestData(),
        }) as {
            data: object & {
                '@id': string,
            },
        }
        Object.assign(response.data, {
            id: response.data['@id'],
        })
        return this.modelConstructor(response.data) as T & { id: string }
    }

    public async update(model: T): Promise<T & { id: string }> {
        await model.onUpdate(this.connection)
        const id = this.getShortObjectIdentifier(model[model.idAttribute])
        await this.connection.put({
            path: `${this.restApiEndpoint}/${id}`,
            data: model.getRequestData(),
        })
        return model as T & { id: string }
    }

    public async delete(model: T) {
        await model.onDelete(this.connection)
        const id = this.getShortObjectIdentifier(model[model.idAttribute])
        await this.connection.delete({
            path: `${this.restApiEndpoint}/${id}`,
            expects: ResponseFormat.NONE,
        })
    }

    public async deleteAll(models: T[]) {
        const ids = models.map((model: T) => {
            return this.getShortObjectIdentifier(model[model.idAttribute])
        })

        await this.connection.delete({
            path: `${this.restApiEndpoint}/delete-all`,
            data: { ids },
            expects: ResponseFormat.NONE,
        })
    }

    // turns 'randomIdString' into '/api/model/randomIdString'
    public getFullObjectIdentifier(identifier: string) {
        if (!identifier.startsWith(`/api${this.restApiEndpoint}`)) {
            return `/api${this.restApiEndpoint}/${identifier}`
        }
        return identifier
    }

    // turns '/api/model/randomIdString' into 'randomIdString'
    public getShortObjectIdentifier(identifier: string) {
        if (identifier.startsWith(`/api${this.restApiEndpoint}`)) {
            return identifier.slice(`/api${this.restApiEndpoint}`.length + 1)
        }
        return identifier
    }

    private createGraphQLOrderingQuery = (ordering: Ordering<T>) => {
        let key = ordering.prop.idChain.join('_')
        if (ordering.prop.isRelation) {
            key += `_${ordering.prop.targetModel.labelAttribute}`
        }
        return `order: { ${key}: "${ordering.direction}" }`
    }

    private createGraphQLFilterQueries = (filter: ResourceFilter<T>): string[] => {
        const formatValue = (
            value: ResourceFilter<T>['values'][keyof ResourceFilter<T>['values']],
        ): string | number | boolean => {
            if (value instanceof DomainModel) {
                return `"${value[value.idAttribute]}"`
            }
            if (value instanceof DateFilter) {
                const dates = [
                    {
                        key: 'after',
                        value: value.after,
                    },
                    {
                        key: 'strictly_before',
                        value: value.before,
                    },
                ]
                    .filter(date => moment.isMoment(date.value))
                    .map(date => `${date.key}: "${toIso8601(date.value as Moment)}"`)
                return `{ ${dates.join(', ')} }`
            }
            if (typeof value === 'boolean') {
                return value
            }
            if (typeof value === 'number') {
                return value
            }
            if (Array.isArray(value)) {
                return `[${value.map(val => formatValue(val)).join(', ')}]`
            }
            if (typeof value === 'object') {
                return JSON.stringify(JSON.stringify(value))
            }
            return `"${value}"`
        }
        const filterQueries = Object.keys(filter.values)
            .filter(key => !key.endsWith('[exists]') && !key.endsWith('[tagged]'))
            .filter(key => filter.values[key] !== undefined)
            .map(propertyKey => {
                const value = filter.values[propertyKey]
                const graphQLKey = propertyKey.replace(/\./g, '_')
                if (Array.isArray(value)) {
                    // @ts-ignore
                    return `${graphQLKey}_list: [${value.map(subValue => `${formatValue(subValue)}`).join(', ')}]`
                }
                return `${graphQLKey}: ${formatValue(value)}`
            })

        const existFilterKeys: string[] = Object.keys(filter.values).filter(key => key.endsWith('[exists]'))
        if (existFilterKeys.length > 0) {
            const existFilters = existFilterKeys.map(existFilterKey => {
                const value = filter.values[existFilterKey]
                const graphQLKey = existFilterKey.replace('[exists]', '').replace(/\./g, '_')
                return `${graphQLKey}: ${formatValue(value)}`
            }).join(', ')
            filterQueries.push(`exists: { ${existFilters} }`)
        }

        const taggedFilterKeys: string[] = Object.keys(filter.values).filter(key => key.endsWith('[tagged]'))
        if (taggedFilterKeys.length > 0) {
            const taggedFilters = taggedFilterKeys.map(taggedFilterKey => {
                const value = filter.values[taggedFilterKey]
                const graphQLKey = taggedFilterKey.replace('[tagged]', '').replace(/\./g, '_')
                return `${graphQLKey}: ${formatValue(value)}`
            }).join(', ')
            filterQueries.push(`tagged: { ${taggedFilters} }`)
        }

        return filterQueries
    }

    /**
     * Build a GraphQL Node query
     * @param properties The properties you would like to query
     * @param depth Used when querying relations, never provide this depth parameter yourself
     * @param alreadyLoaded Already loaded related models, never provide this parameter
     */
    private createGraphQLNodeQuery = (
        properties?: Array<Property<T>>,
        depth: number = 0,
        // tslint:disable-next-line:no-any
        alreadyLoaded: Array<DomainModel<any>> = [],
    ) => {
        const model = this.modelConstructor()
        let modelAttributesToRetrieve: Array<Attribute<T>>
        // tslint:disable-next-line:no-any
        let modelRelationsToRetrieve: Array<RelationInterface<any>>

        let propertiesToPassDown: Array<Property<T>>

        if (!properties) {
            /**
             * WITHOUT PROPERTIES GIVEN
             */
            // if no properties are given, query all attributes
            modelAttributesToRetrieve = model.attributes
            // and all relations that should be eager loaded, but make sure not te enter a recursive loop
            const modelAlreadyLoaded = alreadyLoaded.map(loadedModel => loadedModel.classIdentifier)
                .includes(model.classIdentifier)
            modelRelationsToRetrieve = !modelAlreadyLoaded ? model.relations.filter(relation => relation.eagerLoad) : []
        } else {
            /**
             * WHEN THERE ARE PROPERTIES GIVEN
             */
            propertiesToPassDown = []

            // if properties are given, only query the attributes and relations of this model that are requested
            const propertiesOfThisModel = properties
                .filter(prop => prop.targetModel.classIdentifier === model.classIdentifier)

            // get the attributes
            modelAttributesToRetrieve = propertiesOfThisModel.filter(prop => prop.attribute)
                .map(prop => prop.attribute as Attribute<T>)

            // get the relations
            modelRelationsToRetrieve = []
            properties.forEach(prop => {
                    if (prop.baseModel.classIdentifier === model.classIdentifier && prop.relationChain.length > 0) {
                        const thisModelsDirectRelation = prop.relationChain[0]
                        if (!modelRelationsToRetrieve.map(r => r.key).includes(thisModelsDirectRelation.key)) {
                            modelRelationsToRetrieve.push(thisModelsDirectRelation)
                        }

                        // transform this relation property to a new property that represents the same relation
                        // but then viewed from the directly related model
                        // e.g. 'configurableObject.model.make' becomes 'model.make'
                        if (prop.relationChain.length > 1 || prop.attribute) {
                            propertiesToPassDown.push(
                                Property.parse(thisModelsDirectRelation.model(), prop.idChain.slice(1).join('.')))
                        }
                    }
            })

            // always add id and label attribute to query
            modelAttributesToRetrieve.push(model.getAttribute(model.idAttribute))
            modelAttributesToRetrieve.push(model.getAttribute(model.labelAttribute))
        }

        // create attributes query
        const attributesQuery = modelAttributesToRetrieve.map(attribute => attribute.key).join(' ')

        // create query for all belongs to relations
        const belongsToRelationsQuery = modelRelationsToRetrieve
            .filter(relation => relation instanceof BelongsTo)
            .map(relation => {
                const repository = relation.model().repository(this.connection)
                if (repository.createGraphQLNodeQuery) {
                    let queryKey = relation.key
                    if (relation.isAliasFor) {
                        queryKey += `: ${relation.isAliasFor}`
                    }
                    return `
                                ${queryKey} {
                                    ${relation.model().repository(this.connection)
                        .createGraphQLNodeQuery(propertiesToPassDown, depth + 1, alreadyLoaded.concat(model))}
                                }
                            `
                } else {
                    return relation.key
                }
            }).join(' ')

        // create query for all belongs to many relations
        const belongsToManyRelationsQuery = modelRelationsToRetrieve
            .filter(relation => relation instanceof BelongsToMany)
            .map(relation => {
                const repository = relation.model().repository(this.connection)
                if (repository.createGraphQLNodeQuery) {
                    let queryKey = relation.key
                    if (relation.isAliasFor) {
                        queryKey += `: ${relation.isAliasFor}`
                    }
                    if (relation.filter) {
                        queryKey += `(${repository.createGraphQLFilterQueries(relation.filter).join(', ')})`
                    }
                    const relationSubQuery = relation.model().repository(this.connection)
                        .createGraphQLNodeQuery(propertiesToPassDown, depth + 1, alreadyLoaded.concat(model))
                    return `
                            ${queryKey} {
                                totalCount
                                ${ relationSubQuery.trim().length > 0 ? `edges {
                                    node {
                                        ${relationSubQuery}
                                    }
                                }` : '' }
                            }
                        `
                } else {
                    return relation.key
                }
            }).join(' ')

        return `
            ${attributesQuery}
            ${belongsToRelationsQuery}
            ${belongsToManyRelationsQuery}
        `
    }

    // Used to read the result of a GraphQL query and create a model out of it
    private parseGraphQLNode = (node: EntityRepositoryGraphQLNode): T & { id: string } => {
        const model = this.modelConstructor()
        model.attributes.forEach(attribute => {
            if (node[attribute.key] !== undefined && node[attribute.key] !== null) {
                Object.assign(model, {
                    [attribute.key as keyof T]: node[attribute.key],
                })
            }
        })
        model.relations.forEach(relation => {
            if (node[relation.key]) {
                const repository = relation.model().repository(this.connection)
                if (repository.parseGraphQLNode) {
                    if (relation instanceof BelongsTo) {
                        model[relation.key as keyof T] = repository.parseGraphQLNode(node[relation.key])
                    } else if (relation instanceof BelongsToMany) {
                        // @ts-ignore
                        const { edges, totalCount } = node[relation.key]
                        if (edges) {
                            model[relation.key as keyof T] = edges.map(
                                (edge: { node: EntityRepositoryGraphQLNode }) => repository.parseGraphQLNode(edge.node))
                        }
                        if (totalCount !== undefined) {
                            relation.setTotalCount(totalCount)
                        }
                    }
                }
            }
        })
        return model as T & { id: string }
    }
}
