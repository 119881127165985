import { DomainModel } from '@models/domain/interfaces/DomainModel'
import { Attribute } from '@models/domain/interfaces/Attribute'
import { RelationInterface } from '@models/domain/interfaces/relations/Interface'
import propertyValueForHuman from '@helpers/propertyValueForHuman'
import { ResourceFilter } from '@models/utility/ResourceFilter'
import { FormInputValue } from '@tblg/components'
import { BelongsToMany } from '@models/domain/interfaces/relations/BelongsToMany'
import { i18n } from 'next-i18next'

// tslint:disable-next-line:no-any
export type PropertyValueNoArray<T extends DomainModel<T> = DomainModel<any>> =
    string | number | boolean | DomainModel<T> | undefined

// tslint:disable-next-line:no-any
export type PropertyValue<T extends DomainModel<T> = DomainModel<any>> =
    PropertyValueNoArray<T> | Array<PropertyValueNoArray<T>>

interface PropertyMeta {
    count?: boolean,
    exists?: boolean,
    tagged?: boolean,
}

export class Property<T extends DomainModel<T>> {

    public static parse<T extends DomainModel<T>>(model: T, identifier: string, meta?: PropertyMeta) {
        const idChain = identifier.split('.')
        // tslint:disable-next-line:no-any
        const relationChain: Array<RelationInterface<any>> = []
        let closestModel = model
        let attribute: Attribute<T> | undefined

        idChain.forEach(attributeOrRelation => {
            if (closestModel.isRelation(attributeOrRelation)) {
                const relation = closestModel.getRelation(attributeOrRelation)
                relationChain.push(relation)
                closestModel = relation.model()
            } else if (closestModel.isAttribute(attributeOrRelation)) {
                attribute = closestModel.getAttribute(attributeOrRelation)
            } else {
                throw new Error(`Invalid identifier: ${identifier}`)
            }
        })

        return new Property<T>({
            baseModel: model,
            identifier,
            relationChain,
            attribute,
            meta,
        })
    }

    public baseModel: T
    public key: string
    // tslint:disable-next-line:no-any
    public relationChain: Array<RelationInterface<any>>
    public attribute?: Attribute<T>
    public meta: PropertyMeta = {}

    protected constructor({
        baseModel,
        identifier,
        relationChain,
        attribute,
        meta,
    }: {
        baseModel: T,
        identifier: string,
        // tslint:disable-next-line:no-any
        relationChain: Array<RelationInterface<any>>,
        attribute?: Attribute<T>,
        meta?: PropertyMeta,
    }) {
        this.baseModel = baseModel
        let key = identifier
        if (meta) {
            if (meta.count) {
                key += '[count]'
            }
            if (meta.exists) {
                key += '[exists]'
            }
            if (meta.tagged) {
                key += '[tagged]'
            }
        }
        this.key = key
        this.relationChain = relationChain
        this.attribute = attribute
        if (!attribute && relationChain.length === 0) {
            throw new Error(`Invalid identifier`)
        }
        Object.assign(this.meta, meta)
        if (this.meta.count && !(relationChain[relationChain.length - 1] instanceof BelongsToMany)) {
            throw new Error(`Only properties of BelongsToMany relations can be counted`)
        }
        if (this.meta.tagged && !(relationChain[relationChain.length - 1] instanceof BelongsToMany)) {
            throw new Error(`Only properties of BelongsToMany relations can be tagged`)
        }
    }

    get idChain() {
        return this.key.split('.')
    }

    // tslint:disable-next-line:no-any
    get targetModel(): DomainModel<any> {
        return this.relationChain.length > 0 ? this.relatedModel : this.baseModel
    }

    // tslint:disable-next-line:no-any
    get relatedModel(): DomainModel<any> {
        if (this.relationChain.length > 0) {
            // tslint:disable-next-line:no-any
            return (this.finalRelation as RelationInterface<any>).model()
        } else {
            throw new Error('Only relation properties have a related model')
        }
    }

    // tslint:disable-next-line:no-any
    get relationOwningModel(): DomainModel<any> {
        if (!this.isRelation) {
            throw new Error('Only relation properties have a relation owning model')
        }
        if (this.relationChain.length > 1) {
            // tslint:disable-next-line:no-any
            return (this.relationChain[this.relationChain.length - 2] as RelationInterface<any>).model()
        } else  {
            return this.baseModel
        }
    }

    get isRelation() {
        return !this.attribute
    }

    get finalRelation() {
        return this.relationChain[this.relationChain.length -1 ]
    }

    public getLabel(): string {
        if (this.attribute) {
            return i18n!.t(`${this.targetModel.localeNamespace}:attributes.${this.attribute.key}`)
        } else {
            let label = i18n!.t(`${this.relationOwningModel.localeNamespace}:relations.${this.finalRelation.key}`)
            if (this.meta.exists) {
                label = i18n!.t('common:has_{{item}}', {
                    item: label,
                })
            }
            return label
        }
    }

    // tslint:disable-next-line:no-any
    public setValue(model: DomainModel<any>, value: FormInputValue) {
        const relatedModel = this.idChain.slice(0, -1)
            .reduce((val, key) => Array.isArray(val) ?
                [].concat.apply([], val.map(v => v ? v[key] : undefined)) :
                val ? val[key] : undefined, model)
        relatedModel[this.idChain.pop() as string] = value
    }

    // @ts-ignore
    public getValue(model: DomainModel, forHumans: boolean = false): PropertyValue {
        if (model.constructor !== this.baseModel.constructor) {
            throw new Error(`Given model is not same class as base model of identifier ${this.key}`)
        }

        const idChain = this.idChain

        if (this.meta.count) {
            idChain.pop()
        }

        const value = idChain
            .reduce((val, key) => Array.isArray(val) ?
                [].concat.apply([], val.map(v => v ? v[key] : undefined)) :
                val ? val[key] : undefined, model)

        if (this.finalRelation instanceof BelongsToMany && this.meta.count) {
            // tslint:disable-next-line:no-any
            return (value.getRelation(this.finalRelation.key) as unknown as BelongsToMany<any>).getTotalCount()
        }

        if (this.finalRelation instanceof BelongsToMany && this.meta.exists) {
            // tslint:disable-next-line:no-any
            return (value.getRelation(this.finalRelation.key) as unknown as BelongsToMany<any>).getTotalCount() > 0
        }

        return forHumans ? propertyValueForHuman(this, value) : value
    }

    // tslint:disable-next-line:no-any
    public getValueFromResourceFilter(filter: ResourceFilter<DomainModel<any>>) {
        const valueKey = Object.keys(filter.values).find(propertyKey => {
            const property = Property.parse(filter.model, propertyKey)
            return this.equals(property)
        })
        return valueKey ? filter.values[valueKey] : undefined
    }

    // tslint:disable-next-line:no-any
    public equals(prop: Property<DomainModel<any>>) {
        const equalClosestModel = this.relatedModel.classIdentifier === prop.relatedModel.classIdentifier
        if (equalClosestModel && this.attribute && prop.attribute) {
            return this.attribute.key === prop.attribute.key
        }
        return false
    }

}
