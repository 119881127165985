import { Type } from '@models/domain/interfaces/Attribute'
import capitalize from '@helpers/capitalize'
import { i18n } from 'next-i18next'
import moment from 'moment'
import { DomainModel } from '@models/domain/interfaces/DomainModel'
import { Property, PropertyValue, PropertyValueNoArray } from '@models/utility/Property'
import { humanDateTime } from '@helpers/humanDateTime'
import { humanDate } from '@helpers/humanDate'

const propertyValueForHuman = <T extends DomainModel<T>>(
    prop: Property<T>,
    value: PropertyValue,
    arrayJoin: (array: string[]) => string = (array: string[]) => array.join(', '),
): string => {
    if (Array.isArray(value)) {
        return arrayJoin(value.map(val => propertyValueForHuman(prop, val)))
    }

    let attribute = prop.attribute
    if (!attribute && !prop.meta.count && !prop.meta.exists && value instanceof DomainModel) {
        attribute = {
            key: 'label',
            type: Type.STRING,
        }
        value = value[value.labelAttribute]
    }
    if (attribute) {
        if (attribute.forHumans) {
            return attribute.forHumans(value as PropertyValueNoArray)
        }
        if (attribute.type === Type.ENUM && value) {
            return capitalize(i18n!
                .t(`${prop.targetModel.localeNamespace}:enums.${attribute.key}.${value}`))
        }
        if (attribute.type === Type.BOOLEAN) {
            return capitalize(value ? i18n!.t('common:yes') : i18n!.t('common:no'))
        }
        if (attribute.type === Type.DATE && value) {
            return humanDate(moment(value as string))
        }
        if (attribute.type === Type.DATETIME && value) {
            return humanDateTime(moment(value as string))
        }
        if (attribute.type === Type.JSON && value) {
            return JSON.stringify(value)
        }
    }

    if (prop.meta.exists) {
        return capitalize(value ? i18n!.t('common:yes') : i18n!.t('common:no'))
    }

    if (value === undefined || value === null) {
        return '-'
    }

    return `${value}`
}

export default propertyValueForHuman
